import React, {useContext, useEffect, useState} from 'react';
import classes from './Contact.module.css';
import ChatCircleDots from './../../../img/ChatCircleDots.svg';
import WhatsappLogo from './../../../img/WhatsappLogo.svg';
import CaretLeft from './../../../img/CaretLeft.svg';
import {leftMenu} from '../ProfileMain';
import {useMutation} from '@tanstack/react-query';
import {makeReqest} from '../../../axios';
import AccordionItem from '../Faqs/Accordion';
import faq from './../../../img/Info.svg';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import eyePass from '../../../img/Eyepassword.svg';
import eyeTxt from '../../../img/Eyetxt.svg';
import {AuthContext} from '../../../context/authContext';
import ReCAPTCHA from "react-google-recaptcha";


const Contact = () => {
	const [captchaValue, setCaptchaValue] = useState(null);
	const ctxContact = useContext(leftMenu);
	const {currentUser} = useContext(AuthContext);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [subject, setSubject] = useState('');
	const [text, setText] = useState('');
	const [active, setActive] = useState(null);
	const handleCaptchaChange = (value) => {
		setCaptchaValue(value);
	};
	const handleToggle = (index) => {
		if (active === index) {
			setActive(null);
		}
		else {
			setActive(index);
		}
	};


	useEffect(() => {
		if (currentUser?.body?.user?.user_email) {
			setEmail(currentUser?.body?.user?.user_email);
		}
	}, [])

	const contactAdmin = useMutation({
		mutationFn: () => {
			const form = new FormData();
			form.append('name', name);
			form.append('email', email);
			form.append('message', text);
			form.append('subject', email);
			return axios.post(`https://admin.watchturks.online/api/mail`, form);
		},
		onSuccess: () => {
			// queryClient.invalidateQueries(["movies"]);

			setEmail('');
			setName('');
			setSubject('');
			setText('');

			toast.success('Email Send successfully', {
				position: 'bottom-left',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
				toastId: 'add-movie-success',
			});
		},
		onError: (error) => {
			toast.error(error.response.data.message || error.response.data.error, {
				position: 'bottom-left',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
				toastId: 'add-movies-error',
			});
		},
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!captchaValue) {
			// Kullanıcı reCAPTCHA'yı geçerli bir şekilde doğrulamadıysa uyarı ver
			// ve işlemi durdur.
			toast.warn("Please complete the reCAPTCHA verification.", {
				// ...toast ayarları
			});
			return;
		}
		if (name == '' || email == '' || subject == '' || text == '') {
			toast.warn('Please do not leave any blank space on the form', {
				position: 'bottom-left',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
				toastId: 'add-movies-error',
			});
			console.log(email, name, subject, text)
			return;
		}

		contactAdmin.mutate();
	};
	const showSidebar = () => {
		ctxContact();
	};
	return (
		<div className={classes.contactTop}>
			<div className={classes.headerContent}>
				<div className={classes.contactInner}>
					<img
						className={classes.caretLeft}
						onClick={showSidebar}
						src={CaretLeft}
						alt="CaretLeft"
					/>
					<img
						className={classes.ChatCircleDots}
						src={ChatCircleDots}
						alt="ChatCircleDots"
					/>
					<h1>Contact Us</h1>
				</div>
				<a href="https://wa.me/447312383516">
					<button>
            <span>
              <img src={WhatsappLogo} alt="WhatsappLogo"/> Contact in WhatsApp
            </span>
					</button>
				</a>
			</div>

			<div className={classes.editRow}>
				<div className={classes.inputData}>
					<div className={classes.name}>
						<div>
				                <span>
				                  <input type="text" name="name" placeholder="Name" required={true}
				                         onChange={(e) => setName(e.target.value)}/>
				                </span>
						</div>
					</div>
					<div className={classes.name}>
						<div>
				                <span>
				                  <input type="email" name="email" placeholder="E-mail" required={true}
				                         onChange={(e) => setEmail(e.target.value)} value={email}/>
				                </span>
						</div>
					</div>

					<div className={classes.name}>
						<div>
				                <span>
				                  <input type="text" name="subject" placeholder="Subject" required={true}
				                         onChange={(e) => setSubject(e.target.value)}/>
				                </span>
						</div>
					</div>

					<div>
						<div>
							<span>
								<textarea required={true}
								          placeholder="Type your message here..."
								          onChange={(e) => setText(e.target.value)}
								/>
							</span>
						</div>
					</div>
					<div>
						<ReCAPTCHA
							sitekey="6LdxHkEpAAAAALseCPjHmIDwEQsMv0HbuSL5DI-u"
							onChange={handleCaptchaChange}
						/>
					</div>
					<div>
						<button
							className={classes.sendMessage}
							// type="submit"
							onClick={handleSubmit}
						>
							Send message
						</button>
						<div>
						</div>
					</div>
				</div>



			</div>

		</div>
	);
};

export default Contact;
