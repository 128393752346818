import React, {useContext, useState} from 'react';
import classes from './History.module.css';
import clock from './../../../img/Clock.svg';
import NotSubscribe from '../../NotSubscribe/NotSubscribe';
import CaretLeft from './../../../img/CaretLeft.svg';
import {leftMenu} from '../ProfileMain';
import {AuthContext} from '../../../context/authContext';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import MovieCard from '../../AllMovies/MovieCard';
import SeriesCard from '../../AllSeries/SeriesCard';
import {ToastContainer} from 'react-toastify';


const History = () => {
	const {currentUser} = useContext(AuthContext);
	const ctx = useContext(leftMenu);
	const [sidebar, setSidebar] = useState(false);
	const [filter, setFilter] = useState('all'); // 'all', 'movies', 'series'

	const showSidebar = () => {
		setSidebar(!sidebar);
		ctx();
	};

	// **Loading Durumu ve API Çağrısı**
	const {data: history, isLoading} = useQuery({
		queryKey: ['userHistory'],
		queryFn: () => {
			return axios.get(`https://admin.watchturks.online/api/user/${currentUser?.body.user?.user_id}/history`);
		},
		refetchOnWindowFocus: false,
	});

	const uniqueHistory = [];

	if (history?.data.body.history) {
		history.data.body.history.forEach((item) => {
			if (item.series) {
				const isDuplicate = uniqueHistory.some((uniqueItem) => uniqueItem.series?.serie_id === item.series?.serie_id);
				if (!isDuplicate) {
					uniqueHistory.push(item);
				}
			} else if (item.movie) {
				uniqueHistory.push(item);
			}
		});
	}

	// **Filtreleme**
	const filteredHistory = uniqueHistory.filter(item => {
		if (filter === 'movies') return item.movie;
		if (filter === 'series') return item.series;
		return true;
	});

	return (
		<div className={sidebar ? `${classes.historyTop} ${classes.active2}` : `${classes.historyTop}`}>
			{currentUser?.body.user.user_status ? (
				<>
					<div className={classes.headerContent}>
						<img className={classes.CaretLeft} onClick={showSidebar} src={CaretLeft} alt="CaretLeft"/>
						<img className={classes.bookmarkHeader} src={clock} alt="clock"/>
						<h1>History</h1>
					</div>

					{/* **Filtreleme Butonları** */}
					<div className={classes.filterButtons}>
						<button className={filter === 'all' ? classes.active : ''} onClick={() => setFilter('all')}>
							All
						</button>
						<button className={filter === 'movies' ? classes.active : ''} onClick={() => setFilter('movies')}>
							Movies
						</button>
						<button className={filter === 'series' ? classes.active : ''} onClick={() => setFilter('series')}>
							Series
						</button>
					</div>

					{/* **Yüklenme Durumu** */}
					{isLoading ? (
						<div className={classes.loadingContainer}>
							<div className={classes.spinner}></div>
							<p>Loading...</p>
						</div>
					) : (
						<div className={classes.moviesList}>
							{filteredHistory.length > 0 ? (
								filteredHistory.map((item, index) => (
									<React.Fragment key={index}>
										{item.movie && <MovieCard movie={item.movie} />}
										{item.series && <SeriesCard serie={item.series} />}
									</React.Fragment>
								))
							) : (
								<p className={classes.noData}>No history found.</p>
							)}
						</div>
					)}
				</>
			) : (
				<NotSubscribe/>
			)}
		</div>
	);
};

export default History;