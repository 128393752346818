import React, {useContext, useState} from 'react';
import classes from './SeriesCard.module.css';
import star from './../../img/star.svg';
import save from './../../img/save.svg';
import bookmark from './../../img/bookmark.svg';
import NotificationFilled from './../../img/notificationFilled.svg';
import {useNavigate} from 'react-router';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import filledWatchLaterIcon from './../../img/BookmarkSimple.svg';
import {AuthContext} from '../../context/authContext';
import axios from 'axios';
import {toast} from 'react-toastify';

const SeriesCard = ({serie}) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const currentUser = useContext(AuthContext);

    // **State for Local UI Update**
    const [isWishlist, setIsWishlist] = useState(serie.is_wishlist);
    const [isNotificationOn, setIsNotificationOn] = useState(serie.is_notification_on);

    const addToWatchList = useMutation({
        mutationFn: () => {
            return axios.post(`https://admin.watchturks.online/api/user/${currentUser?.currentUser?.body.user?.user_id}/wishlist/series/${serie.serie_id}`);
        },
        onSuccess: (response) => {
            const isAdded = response.data.body.message.includes('added');

            // ✅ **Instantly Update UI**
            setIsWishlist(isAdded ? '1' : '0');

            // ✅ **Toast Notification**
            toast.success(`${serie?.serie_title} (${serie?.serie_year}) ${isAdded ? 'added to' : 'removed from'} Watchlist! 📺`, {
                position: 'bottom-left',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'light',
            });

            // **Invalidate Queries to Sync with Backend**
            queryClient.invalidateQueries(['projects']);
            queryClient.invalidateQueries(['userHistory']);
            queryClient.invalidateQueries(['watchList']);
        },
    });

    const addToNotification = useMutation({
        mutationFn: () => {
            return axios.post(`https://admin.watchturks.online/api/user/${currentUser?.currentUser?.body.user?.user_id}/user_notifications/${serie.serie_id}`);
        },
        onSuccess: (response) => {
            const isNotificationEnabled = response.data.body.message.includes('turned on');

            // ✅ **Instantly Update UI**
            setIsNotificationOn(isNotificationEnabled ? '1' : '0');

            // ✅ **Toast Notification**
            toast.success(`${serie?.serie_title} notifications ${isNotificationEnabled ? 'enabled' : 'disabled'} 🔔`, {
                position: 'bottom-left',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'light',
            });

            // **Invalidate Queries to Sync with Backend**
            queryClient.invalidateQueries(['projects']);
            queryClient.invalidateQueries(['userHistory']);
            queryClient.invalidateQueries(['watchList']);
        },
    });

    return (
        <div
            className={classes.cardUiTop}
            onClick={() => navigate(`/seriesdetail/${serie.serie_id}#details`)}
        >
            <div className={classes.headerCard}>
                <div className={classes.rating}>
                    <div>
                        <img className={classes.star} src={star} alt="star"/>
                        <h3>10</h3>
                    </div>

                    {/* ❤️ Watchlist Icon - Updates Instantly */}
                    {currentUser.currentUser?.body.user.user_status && (
                        <img
                            className={classes.save}
                            src={isWishlist === '1' ? filledWatchLaterIcon : save}
                            alt="save"
                            onClick={(e) => {
                                e.stopPropagation();
                                addToWatchList.mutate();
                            }}
                        />
                    )}

                    {/* 🔔 Notification Icon - Updates Instantly */}
                    {currentUser.currentUser?.body.user.user_status && (
                        <img
                            className={classes.bookmark}
                            src={isNotificationOn === '1' ? NotificationFilled : bookmark}
                            alt="bookmark"
                            onClick={(e) => {
                                e.stopPropagation();
                                addToNotification.mutate();
                            }}
                        />
                    )}
                </div>
                <img
                    className={classes.imgDefault}
                    src={`${process.env.REACT_APP_BASE_URL}/uploads/${serie?.serie_image}`}
                    alt="top series"
                />

                <div className={classes.hoverCard}>
                    <div className={classes.details}>
                        <h4>{serie?.serie_title}</h4>
                    </div>
                </div>
            </div>
            <div className={classes.footer}>
                <h3>{serie?.serie_title}</h3>
                <p>
                    <span>{serie?.serie_year} </span>
                </p>
            </div>
        </div>
    );
};

export default SeriesCard;