import React, {useContext} from 'react';
import Recommended from '../RecommendedMovie/Recommended';
import FeatureMovie from './FeatureMovie/FeatureMovie';
import FeaturedSeries from './FeatureSeries/FeaturedSeries';
import HighRating from './HighRating/HighRating';
import HomeSliderTop from './HomeSliderTop/HomeSliderTop';
import LatestEpisode from './LatestEpisode/LatestEpisode';
import PaymentDetail from './PaymentDetail/PaymentDetail';
import RecentMovies from './RecentMovies/RecentMovies';
import RecentSeries from './RecentSeries/RecentSeries';
// import TopMovies from "./TopMovies/TopMovies";
import TopSeries from './TopSeries/TopSeries';
import {AuthContext} from '../../context/authContext';
import TopMoviesNew from './_TopMovies/index';
import TopSeriesNew from './_TopSeries/index';

const HomeContent = () => {
    const {currentUser} = useContext(AuthContext);

    return (
        <>


            {currentUser ? (
                <>
                    <HomeSliderTop/>
                    <TopSeriesNew/>
                    {currentUser.body.user.user_status === '0' && <PaymentDetail/>}
                    <RecentSeries/>
                    <LatestEpisode/>
                    <TopMoviesNew/>
                    <RecentMovies/>
                    {/*<TopMovies />*/}
                </>
            ) : (
                <PaymentDetail/>
            )}

        </>
    );
};

export default HomeContent;
