import React, {useEffect, useContext} from "react";
import PaymentPlan from "./PaymentPlan";
import logo from "./../../img/Logo.svg";
import classes from "./ChoosePayment.module.css";
import {useNavigate} from "react-router";
import {AuthContext} from "../../context/authContext";

const images = [
    require("./../../img/yabancicekleri.jpg"),
    require("./../../img/kurulusosman.jpg"),
    require("./../../img/karasevda.jpg"),
    require("./../../img/muhtesemyuzyil.jpg"),
    require("./../../img/kurtseyitvesura.jpg"),
    require("./../../img/hercai.webp"),
];

const ChoosePayment = () => {
    const navigate = useNavigate();
    const {currentUser} = useContext(AuthContext);
    const user = currentUser?.body?.user;

    useEffect(() => {
        (function (d, w, c) {
            w.BrevoConversationsID = '634da3fd9246621bcf4be71d';
            w[c] = w[c] || function () {
                (w[c].q = w[c].q || []).push(arguments);
            };
            const s = d.createElement('script');
            s.async = true;
            s.src = 'https://conversations-widget.brevo.com/brevo-conversations.js';
            d.head.appendChild(s);
        })(document, window, 'BrevoConversations');

        return () => {
            const script = document.querySelector('script[src="https://conversations-widget.brevo.com/brevo-conversations.js"]');
            if (script) script.remove();
        };
    }, []);

    const createInfiniteLoopImages = (images, loopCount) =>
        Array.from({length: loopCount}).flatMap(() => images);

    const distributeImages = (images) => {
        const leftImages = [], centerImages = [], rightImages = [];
        images.forEach((img, i) => {
            if (i % 3 === 0) leftImages.push(img);
            else if (i % 3 === 1) centerImages.push(img);
            else rightImages.push(img);
        });
        return {leftImages, centerImages, rightImages};
    };

    const loopedImages = createInfiniteLoopImages(images, 10);
    const {leftImages, centerImages, rightImages} = distributeImages(loopedImages);

    // 👇 Eğer user henüz hazır değilse, loading ekranı göster
    if (!user) {
        return (
            <div className={classes.loadingScreen}>
                <div className={classes.logo} style={{marginTop: '2rem'}}>
                    <img src={logo} alt="logo"/>
                    <h2>WatchTurks</h2>
                </div>
                <p style={{color: "white", textAlign: "center", marginTop: "2rem"}}>Loading your profile...</p>
            </div>
        );
    }

    return (
        <div className={classes.CreateTop}>
            <div className={classes.row}>
                <div className={classes.left}>
                    <div className={classes.logo} onClick={() => navigate("/")} style={{cursor: "pointer"}}>
                        <img src={logo} alt="logo"/>
                        <h2>WatchTurks</h2>
                    </div>

                    <PaymentPlan currentUser={currentUser}/>
                </div>

                <div className={classes.right}>
                    <div className={classes["image-grid"]}>
                        <div className={`${classes.column} ${classes["left-column"]}`}>
                            {leftImages.map((image, index) => (
                                <img key={index} src={image} alt={`Slide ${index}`}/>
                            ))}
                        </div>
                        <div className={`${classes.column} ${classes["center-column"]}`}>
                            {centerImages.map((image, index) => (
                                <img key={index} src={image} alt={`Slide ${index}`}/>
                            ))}
                        </div>
                        <div className={`${classes.column} ${classes["right-column"]}`}>
                            {rightImages.map((image, index) => (
                                <img key={index} src={image} alt={`Slide ${index}`}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChoosePayment;