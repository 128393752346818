import React from 'react';
import {useState} from 'react';
import classes from './ForgotForm.module.css';
import {useMutation} from '@tanstack/react-query';
import axios from 'axios';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotForm = () => {
	// const [forgotEmail, setForgotEmail] = useState(false);
	const [email, setEmail] = useState('');

	// const showStepEmail = () => {
	//   setForgotEmail(true);
	// };
	const addMutation = useMutation(
		() => {
			const form = new FormData();
			form.append('email', email);

			return axios.post(`https://admin.watchturks.online/api/auth/forgot-password`, form);
		},
		{
			onSuccess: (data) => {
				toast.success('verification link send check your email', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
					toastId: 'add-movie-success',
				});
				setEmail('');
			},
			onError: (error) => {
				toast.error(error.response.data.message || error.response.data.error, {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
					toastId: 'add-movies-error',
				});
			},
		}
	);
	const handlesubmit = (e) => {
		e.preventDefault();
		addMutation.mutate();
	};
	return (
		<div className={classes.ForgorPass}>
			<h1>Forgot password?</h1>
			<p>Please enter your email address</p>
			<form onSubmit={handlesubmit}>
				{/* {!forgotEmail && ( */}
				<>
					<div className={classes.inputStroke}>
            <span>
              <input
	              type="email"
	              value={email}
	              placeholder="E-mail"
	              onChange={(e) => setEmail(e.target.value)}
              />
            </span>
					</div>
					<button
						className={classes.createBtn}
						// onClick={showStepEmail}
					>
						Submit
					</button>
				</>
				{/* )} */}
				{/* {forgotEmail && (
          <>
            <div className={classes.followSteps}>
              <span>
                <div className={classes.followStepBtn}>
                  Follow the steps on the email
                </div>
              </span>
            </div>
          </>
        )} */}
			</form>

		</div>
	);
};
export default ForgotForm;
