import React, {useContext, useState} from 'react';
import classes from './SliderCard.module.css';
import star from './../../img/star.svg';
import save from './../../img/save.svg';
import bookmark from './../../img/bookmark.svg';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {makeReqest} from '../../axios';
import {useNavigate} from 'react-router';
import filledWatchLaterIcon from '../.././img/BookmarkSimple.svg';
import {AuthContext} from '../../context/authContext';
import NotificationFilled from '../../img/notificationFilled.svg';
import {toast} from 'react-toastify';
import axios from 'axios';

const SliderCard = ({seriesItem, isClickAllowed}) => {
	const [isSwiping, setIsSwiping] = useState(false);
	const [isWishlist, setIsWishlist] = useState(seriesItem.is_wishlist);
	const [isNotificationOn, setIsNotificationOn] = useState(seriesItem.is_notification_on);

	const handleSwipeStart = () => setIsSwiping(true);
	const handleSwipeEnd = () => setIsSwiping(false);
	const handleLinkClick = (e) => {
		if (isSwiping) {
			e.preventDefault();
		} else if (isClickAllowed) {
			navigate(`/seriesdetail/${seriesItem.serie_id}#details`);
		}
	};

	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const currentUser = useContext(AuthContext);

	// 🎬 **Add / Remove from Watchlist**
	const addToWatchList = useMutation({
		mutationFn: () => {
			const form = new FormData();
			form.set('user_id', currentUser?.currentUser?.body.user?.user_id);
			form.set('serie_id', seriesItem.serie_id);
			return axios.post(`https://admin.watchturks.online/api/user/${currentUser?.currentUser?.body.user?.user_id}/wishlist/series/${seriesItem.serie_id}`, form);
		},
		onSuccess: (response) => {
			const isAdded = response.data.body.message.includes('added');

			// ✅ **Update UI instantly**
			setIsWishlist(isAdded ? '1' : '0');

			// ✅ **Show Toast Notification**
			toast.success(
				`${seriesItem?.serie_title} (${seriesItem?.serie_year}) ${isAdded ? 'added to' : 'removed from'} Watchlist! 📺`,
				{
					position: 'bottom-left',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: 'light',
				}
			);

			// **Invalidate Queries to Sync Backend**
			queryClient.invalidateQueries(['home-data']);
			queryClient.invalidateQueries(['watchList']);
		},
	});

	// 🔔 **Enable / Disable Notifications**
	const addToNotification = useMutation({
		mutationFn: () => {
			const form = new FormData();
			form.set('user_id', currentUser?.currentUser?.body.user?.user_id);
			form.set('serie_id', seriesItem.serie_id);
			return axios.post(`https://admin.watchturks.online/api/user/${currentUser?.currentUser?.body.user?.user_id}/user_notifications/${seriesItem.serie_id}`, form);
		},
		onSuccess: (response) => {
			const isTurnedOn = response.data.body.message.includes('turned on');

			// ✅ **Update UI instantly**
			setIsNotificationOn(isTurnedOn ? '1' : '0');

			// ✅ **Show Toast Notification**
			toast.success(
				`${seriesItem?.serie_title} notifications ${isTurnedOn ? 'enabled' : 'disabled'} 🔔`,
				{
					position: 'bottom-left',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: 'light',
				}
			);

			// **Invalidate Queries to Sync Backend**
			queryClient.invalidateQueries(['projects']);
		},
	});

	// **Get Genre Text**
	const genreText = () => {
		if (seriesItem?.genre_title) return seriesItem.genre_title;
		if (seriesItem?.genres) return seriesItem.genres.map((genre) => genre.genre_title).join(', ');
		return null;
	};

	return (
		<div className={classes.cardUiTop} onTouchStart={handleSwipeStart} onTouchEnd={handleSwipeEnd} onClick={handleLinkClick}>
			<div className={classes.headerCard}>
				<div className={classes.rating}>
					{(seriesItem?.avg_rating && seriesItem?.avg_rating != "0") && (
						<div>
							<img className={classes.star} src={star} alt="star" />
							<h3>{parseFloat(parseInt(seriesItem?.avg_rating)) || ""}</h3>
						</div>
					)}

					{/* ❤️ Watchlist Button - Instant UI Update */}
					{currentUser.currentUser?.body.user.user_status && (
						<img
							className={classes.save}
							src={isWishlist === '1' ? filledWatchLaterIcon : save}
							alt="save"
							onClick={(e) => {
								e.stopPropagation();
								addToWatchList.mutate();
							}}
						/>
					)}

					{/* 🔔 Notification Button - Instant UI Update */}
					{currentUser.currentUser?.body.user.user_status && (
						<img
							className={classes.bookmark}
							src={isNotificationOn === '1' ? NotificationFilled : bookmark}
							alt="bookmark"
							onClick={(e) => {
								e.stopPropagation();
								addToNotification.mutate();
							}}
						/>
					)}
				</div>
				<img
					className={classes.imgDefault}
					src={`${process.env.REACT_APP_BASE_URL}/uploads/${seriesItem?.serie_image}`}
					alt="top series"
				/>

				<div className={classes.hoverCard}>
					<div className={classes.details}>
						<h4>{seriesItem?.serie_title}</h4>
						<p>{seriesItem?.serie_description}</p>
					</div>
				</div>
			</div>
			<div className={classes.footer}>
				<h3>{seriesItem?.serie_title}</h3>
				<p>
					{seriesItem?.serie_year} {genreText() && <span style={{position: 'relative', top: '-4px', marginLeft: '10px'}}>.</span>}
					<span style={{marginLeft: '10px'}}>{genreText()}</span>
				</p>
			</div>
		</div>
	);
};

export default SliderCard;