import React, {useContext, useState} from 'react';
import classes from './WatchLater.module.css';
import watchLater from './../../../img/watchlater.svg';
import CaretLeft from './../../../img/CaretLeft.svg';
import NotSubscribe from '../../NotSubscribe/NotSubscribe';
import {leftMenu} from '../ProfileMain';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import MovieCard from '../../AllMovies/MovieCard';
import SeriesCard from '../../AllSeries/SeriesCard';
import {AuthContext} from '../../../context/authContext';

const WatchLater = () => {
    const ctx = useContext(leftMenu);
    const {currentUser} = useContext(AuthContext);

    // **Filtreleme için State**
    const [filter, setFilter] = useState('all'); // 'all', 'movies', 'series'

    const showSidebar = () => {
        ctx();
    };

    const {data} = useQuery({
        queryKey: ['watchList'],
        queryFn: () => {
            return axios.get(`https://admin.watchturks.online/api/user/${currentUser?.body.user?.user_id}/wishlist`);
        },
    });

    // **Filtreleme Fonksiyonu**
    const filteredData = data?.data.body.wactchLater.filter(item => {
        if (filter === 'movies') return item.movie;
        if (filter === 'series') return item.series;
        return true;
    });

    return (
        <div className={classes.watchLaterTop}>
            {currentUser?.body.user.user_status ? (
                <>
                    <div className={classes.headerContent}>
                        <img onClick={showSidebar} className={classes.CaretLeft} src={CaretLeft} alt="CaretLeft" />
                        <img className={classes.bookmarkHeader} src={watchLater} alt="watchLater" />
                        <h1>Favorites</h1>
                    </div>

                    {/* **Filtreleme Butonları** */}
                    <div className={classes.filterButtons}>
                        <button className={filter === 'all' ? classes.active : ''} onClick={() => setFilter('all')}>
                            All
                        </button>
                        <button className={filter === 'movies' ? classes.active : ''} onClick={() => setFilter('movies')}>
                            Movies
                        </button>
                        <button className={filter === 'series' ? classes.active : ''} onClick={() => setFilter('series')}>
                            Series
                        </button>
                    </div>

                    <div className={classes.row}>
                        {filteredData?.map((movieAndSeries) => (
                            <>
                                {movieAndSeries.movie && <MovieCard movie={movieAndSeries.movie} history={true} />}
                                {movieAndSeries.series && <SeriesCard serie={movieAndSeries.series} history={true} />}
                            </>
                        ))}
                    </div>
                </>
            ) : (
                <NotSubscribe />
            )}
        </div>
    );
};

export default WatchLater;