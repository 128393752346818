import React, {useContext} from "react";
import SubscriptionCardUi from "../SubscriptionCardUi/SubscriptionCardUi";
import CaretLeft from "./../../../img/CaretLeft.svg";
import classes from "./Subscription.module.css";
import {leftMenu} from "../ProfileMain";
import {AuthContext} from "../../../context/authContext";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useNavigate} from "react-router-dom";

const Subscription = () => {
    const {currentUser} = useContext(AuthContext);
    const subCtx = useContext(leftMenu);
    const navigate = useNavigate();

    const showSidebar = () => {
        subCtx();
    };

    // Paketleri getir
    const {
        data: packagesData,
        isLoading: isLoadingPackages
    } = useQuery({
        queryKey: ["packages"],
        queryFn: async () => {
            const response = await axios.get(`https://admin.watchturks.online/api/packages`);
            return response.data.body?.packages || []; // null/undefined koruması
        },
    });

    // Kullanıcının aktif aboneliğini getir
    const {
        data: subscriptionData,
        isLoading: isLoadingSubscription,
        isError: isSubscriptionError
    } = useQuery({
        queryKey: ["currentSubscription", currentUser?.body?.user?.user_id],
        queryFn: async () => {
            const response = await axios.get(
                `https://admin.watchturks.online/api/subscriptions/${currentUser?.body?.user?.user_id}/current`
            );
            return response.data;
        },
        enabled: !!currentUser?.body?.user?.user_id,
    });

    // Stripe aboneliğini yönet
    const handleManageSubscription = async () => {
        try {
            const response = await axios.post(
                `https://admin.watchturks.online/api/subscriptions/${currentUser?.body?.user?.user_id}/manage`
            );
            if (response.data.url) {
                window.location.href = response.data.url;
            }
        } catch (error) {
            toast.error(error?.response?.data?.error || "Failed to redirect to subscription management page", {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
        }
    };

    // Kalan günleri hesapla
    const calculateRemainingDays = () => {
        if (!subscriptionData?.end_date) return 0;

        const expiredDate = new Date(subscriptionData.end_date);
        const currentDate = new Date();
        const differenceInTime = expiredDate.getTime() - currentDate.getTime();

        return differenceInTime > 0 ? Math.ceil(differenceInTime / (1000 * 60 * 60 * 24)) : 0;
    };

    // Plan seçimi
    const handlePlanSelect = (pkgId) => {
        if (subscriptionData?.source === "stripe" && subscriptionData?.status === "active") {
            handleManageSubscription();
        } else {
            navigate(`/choosepayment?plan_id=${pkgId}`);
        }
    };

    return (
        <div className={classes.subscriptionTop}>
            {isLoadingSubscription ? (
                <p>Loading subscription...</p>
            ) : isSubscriptionError || subscriptionData?.message === "No subscription found." ? (
                <div className={classes.inner}>
                    <img className={classes.CaretLeft} onClick={showSidebar} src={CaretLeft} alt="CaretLeft"/>
                    <h1>No Active Subscription</h1>
                    <p>You currently don't have an active subscription. Please choose a plan to continue.</p>
                    <div className={classes.morePlan}>
                        {isLoadingPackages ? (
                            <p>Loading available plans...</p>
                        ) : (
                            Array.isArray(packagesData) &&
                            packagesData.map((pkg) => (
                                <SubscriptionCardUi
                                    key={pkg.id}
                                    subscriptionPkg={pkg}
                                    onPlanSelect={handlePlanSelect}
                                    hasSubscription={false}
                                />
                            ))
                        )}
                    </div>
                </div>
            ) : (
                <div className={classes.inner}>
                    <img onClick={showSidebar} className={classes.CaretLeft} src={CaretLeft} alt="CaretLeft"/>
                    <h1>Your Plan</h1>
                    <p>Watch all you want with English subs. Recommendations just for you. Change or cancel your
                        plan anytime.</p>

                    <div className={classes.currentPlan}>
                        <div className={classes.planName}>
                            <h2>{subscriptionData.plan_name}</h2>
                            <h2>${subscriptionData.price} / {subscriptionData.interval}</h2>
                            <p style={{margin: "0"}}>Valid until: {subscriptionData.end_date}</p>
                            <p style={{margin: "0"}}>Remaining: {calculateRemainingDays()} days</p>
                            {subscriptionData.cancel_at_period_end && (
                                <p style={{color: "orange", margin: "0"}}>
                                    Cancellation Scheduled - Valid until: {subscriptionData.end_date}
                                </p>
                            )}
                        </div>
                        <div className={classes.manageSub}>
                            {subscriptionData?.source === "stripe" && (
                                <button className={classes.manageBtn} onClick={handleManageSubscription}>
                                    Manage Subscription
                                </button>
                            )}
                        </div>
                    </div>

                    <div className={classes.morePlan}>
                        {isLoadingPackages ? (
                            <p>Loading available plans...</p>
                        ) : (
                            Array.isArray(packagesData) &&
                            packagesData
                                .filter((pkg) => pkg.id !== subscriptionData.plan_id)
                                .map((pkg) => (
                                    <SubscriptionCardUi
                                        key={pkg.id}
                                        subscriptionPkg={pkg}
                                        onPlanSelect={handlePlanSelect}
                                        hasSubscription={!!subscriptionData}
                                    />
                                ))
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Subscription;