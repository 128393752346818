import React, {useContext} from 'react';
import classes from './NewPassForm.module.css';
import {useState} from 'react';
import eyePass from './../../img/Eyepassword.svg';
import eyeTxt from './../../img/Eyetxt.svg';
import {useLocation} from 'react-router';
import {useMutation} from '@tanstack/react-query';
import axios from 'axios';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {AuthContext} from '../../context/authContext';

const NewPassForm = () => {
	const [passwordType, setPasswordType] = useState('password');
	const [confiredPassword, setConfiredPassword] = useState('');
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get('token');
	const handlePasswordChange = (evnt) => {
		setConfiredPassword(evnt.target.value);
	};
	const togglePassword = () => {
		if (passwordType === 'password') {
			setPasswordType('text');
			return;
		}
		setPasswordType('password');
	};

	const [passwordTypeNew, setPasswordTypeNew] = useState('password');
	const [newPassword, setNewPassword] = useState('');
	const handlePasswordNew = (evnt) => {
		setNewPassword(evnt.target.value);
	};
	const togglePasswordNew = () => {
		if (passwordTypeNew === 'password') {
			setPasswordTypeNew('text');
			return;
		}
		setPasswordTypeNew('password');
	};

	const {login} = useContext(AuthContext);

	const addMutation = useMutation(
		() => {
			const form = new FormData();
			form.set('newPassword', newPassword);

			return axios.post(`https://admin.watchturks.online/api/auth/reset?token=${token}`, form);
		},
		{
			onSuccess: async (response) => {
				toast.success('Password reset successfully', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
					toastId: 'add-movie-success',
				});
				setConfiredPassword('');
				setNewPassword('');

				await login({email: response.data.body.user.user_email, inputPassword: newPassword});
				window.location.href = '/';
			},
			onError: (error) => {
				toast.error(error.response.data.message || error.response.data.error, {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
					toastId: 'add-movies-error',
				});
			},
		}
	);

	const handleSubmit = (e) => {
		e.preventDefault();
		addMutation.mutate();
	};

	return (
		<div className={classes.newPass}>
			<h1>Create a new password</h1>
			<form onSubmit={handleSubmit}>
				<div className={classes.pass}>
          <span>
            <input
	            type={passwordTypeNew}
	            onChange={handlePasswordNew}
	            value={newPassword}
	            name="password"
	            placeholder="New password"
            />
            <div className="input-group-btn">
              <button type="button" onClick={togglePasswordNew}>
                {passwordTypeNew === 'password' ? (
	                <img src={eyePass} alt="eyePass"/>
                ) : (
	                <img src={eyeTxt} alt="eyeTxt"/>
                )}
              </button>
            </div>
          </span>
				</div>
				<div className={classes.pass}>
          <span>
            <input
	            type={passwordType}
	            onChange={handlePasswordChange}
	            value={confiredPassword}
	            name="password"
	            placeholder="Confirm new password"
            />
            <div className="input-group-btn">
              <button type="button" onClick={togglePassword}>
                {passwordType === 'password' ? (
	                <img src={eyePass} alt="eyePass"/>
                ) : (
	                <img src={eyeTxt} alt="eyeTxt"/>
                )}
              </button>
            </div>
          </span>
				</div>
				<button className={classes.createBtn}>Submit</button>
			</form>

		</div>
	);
};
export default NewPassForm;
