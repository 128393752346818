import React, {useContext} from 'react';
import classes from './MovieCard.module.css';
import star from './../../img/star.svg';
import save from './../../img/save.svg';
import {useNavigate} from 'react-router';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import filledWatchLaterIcon from './../../img/BookmarkSimple.svg';
import {AuthContext} from '../../context/authContext';
import axios from 'axios';
import {toast} from 'react-toastify';

const MovieCard = ({movie, history}) => {
	const {currentUser} = useContext(AuthContext);
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const addToWatchList = useMutation({
		mutationFn: () => {
			return axios.post(`https://admin.watchturks.online/api/user/${currentUser?.body.user?.user_id}/wishlist/movies/${movie?.movie_id}`);
		},
		onSuccess: (response) => {
			const isAdded = response.data.body.message.includes('added');

			// **Movie objesini güncelleyerek kalbi değiştirelim**
			movie.is_wishlist = isAdded ? '1' : '0';

			// **Toast Bildirimi (Filmin Adı ve "Movie" Eklendi)**
			toast.success(`${movie?.movie_title} (${movie?.movie_year}) ${isAdded ? 'added to' : 'removed from'} Watchlist! 🎬`, {
				position: 'bottom-left',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: 'light',
			});

			// **Sayfadaki Veriyi Güncelle (Watchlist ve History Yeniden Çekilsin)**
			queryClient.invalidateQueries(['loadMovies']);
			queryClient.invalidateQueries(['userHistory']);
			queryClient.invalidateQueries(['watchList']);
		},
	});

	return (
		<div
			className={classes.cardUiTop}
			onClick={() => navigate(`/moviedetail/${movie?.movie_id}#details`)}
		>
			<div className={classes.headerCard}>
				<div className={classes.rating}>
					<div>
						<img className={classes.star} src={star} alt="star"/>
						<h3>10</h3>
					</div>
					{currentUser?.body.user.user_status && (
						<img
							style={{zIndex: '1000'}}
							className={classes.save}
							src={movie?.is_wishlist == '1' ? filledWatchLaterIcon : save}
							alt="save"
							onClick={(e) => {
								e.stopPropagation();
								addToWatchList.mutate();
							}}
						/>
					)}
				</div>
				<img
					className={classes.imgDefault}
					src={`${process.env.REACT_APP_BASE_URL}/uploads/${movie?.movie_image}`}
					alt="movie poster"
				/>

				<div className={classes.hoverCard}>
					<div className={classes.details}>
						<h4>{movie?.movie_title}</h4>
					</div>
				</div>
			</div>
			<div className={classes.footer}>
				<h3>{movie?.movie_title}</h3>
				<p>{movie?.movie_year}</p>
			</div>
		</div>
	);
};

export default MovieCard;